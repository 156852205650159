@import "../../styles/_variables.scss";

.custom-selector{
	position: relative;
	display: inline-block;
	vertical-align: inherit;
	padding: 0 !important;

	.option-tag{
		display: inline-block;
		background: $grey-light;
		padding: 3px 10px;
		min-width: 50px;
		text-align: center;
	}

	.option-label {
		margin-left: 15px;
	}

	.selected-option{
		cursor: pointer;
		padding: 7px 10px;
		&:before {
			content: ' ';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 999;
		}
		&:focus {
			outline: none;
		}

		.option-label{
			margin-left: 7px;
		}

		.option-tag{
			padding: 3px 7px;
			min-width: auto;
		}		
	}



	.list-options {
		position: absolute;
		z-index: 999999;
		border: 1px solid $border-color;
		border-radius: 3px;
		background: #ffffff;
		margin-top: 8px;
		padding: 8px 0;
		max-height: 250px;
		overflow-y: auto;
		width: auto;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium $grey-light; // 1 barra 2 fons (firefox)
	}

	.list-option {
		cursor: pointer;
		padding: 5px 8px;
		white-space: nowrap;

		// &.has-label {
		// 	padding: 0px 20px 3px 8px;
		// }
		&:hover {
			background: $grey-light;
		}
		&:focus {
			outline: none;
			background: $grey-light;            
		}
	}

	.list-option-empty{
		padding: 5px 8px;
		white-space: nowrap;
		min-width: 150px;
	}

	.filterBox{
		padding: 0px 7px 10px 7px;
		svg{
			right: 10px;
		}
	}

	.disabled{
		pointer-events: none;
	}

	// tag only
	&.no-label{
		width: 100%;
		background: none;

		.selected-option{
			padding: 0;

			.option-tag{
				width: 100%;
				padding: 6px 25px;
			}
		}

		&:hover, &.active{
			.selected-option{
				.option-tag{
					background-image: url("../../assets/icons/arrow-down-dark.svg");
					background-repeat: no-repeat;
					background-position: calc(100% - 10px) 50%;
					background-size: auto 10px;
					opacity: 0.85;
				}			
			}
		}
	}
}
