// @import "../../styles/_variables.scss";
@import "../../styles/_buttons.scss";

// widget templates

.page-widget-templates {
	.list-columns {
		margin-top: 5px;
		margin-bottom: 15px;

		li {
			padding-top: 8px;
			padding-bottom: 8px;
			font-size: 12px;
			border-bottom: 1px solid $border-color;

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}



// plan view modal
.widget-template-edit {
	.list-columns {
		background: $primary-lightest;
	}

	.column-wrap {
		overflow-y: auto;
		transition: all 0.2s ease;
		scrollbar-width: thin;
		scrollbar-color: $grey-medium rgba(0, 0, 0, 0.07); // 1 barra 2 fons (firefox)	
		height: 450px;
	}
}

.column-item-selectbox {
	// width: 60px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;

	label {
		margin-right: 5px;
		font-weight: normal;
	}

	&:last-of-type {
		margin-right: 0;
	}
}

.column-item {
	z-index: 999 !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;

	label {
		margin-bottom: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		user-select: auto !important;
		display: inline-flex;
		flex-direction: column;
		flex-flow: wrap;
	}

	.column-right,
	.column-item-selectbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.column-move {
		cursor: grab;
		padding: 3px 10px;
	}

	.column-move-icon,
	.column-label-btn {
		transition: all .25s ease-in-out;
		-moz-transition: all .25s ease-in-out;
		-webkit-transition: all .25s ease-in-out;
		opacity: 0;

		&:hover {
			color: $primary;
		}
	}

	.column-left {
		display: flex;
		align-items: flex-start;
		flex: 1;
		padding-right: 15px;
	}

	.column-item-label {
		font-weight: 500;
	}

	.column-label-btn {
		@extend .btn-transparent;
		padding: 8px;
	}

	&:nth-child(odd) {
		background: $grey-light;
	}

	&:hover {

		.column-move-icon,
		.column-label-btn {
			opacity: 0.6;
		}
	}

	&.column-item-active {
		background: white;
		box-shadow: 0 4px 8px 0 rgba(91, 73, 254, 0.24);
		cursor: grabbing !important;

		&:hover {
			cursor: grabbing !important;
		}

		.column-move-icon {
			opacity: 0.4;
		}
	}

}