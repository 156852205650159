@import "../../styles/_variables.scss";

.export-config {
	font-size: 13px;
	margin-top: 5px;

	&.form-switch {
		display: flex;
		align-items: center;
		width: 270px;
		padding-left: 10px;
		padding-right: 5px;

		.form-label {
			font-weight: 400;
			margin-left: 5px;
			cursor: pointer;
			line-height: 1.15em;

		}

		.switch {
			width: 50px;
			margin-right: 5px;
		}

		.react-switch-label {
			width: 35px;
			height: 20px;

			.react-switch-button {
				height: 16px;
			}
		}
	}
}

.popover-location {
	.popover-content {
		padding: 10px 10px;

		ul {
			margin-bottom: 5px;
		}

		a {
			color: $color-pink;
		}
	}
}

.btn-time-location {
	display: flex;
	align-items: center;

	span {
		border-bottom: 1px dashed $label-color;
	}

	.icon {
		color: $label-color;
		margin-left: 4px;
	}
}



.page-times {
	.btn-group {
		.icon {
			font-size: 18px;
		}
	}

	.icon-comment+.icon-alert {
		margin-left: 5px;
	}

	.table-rested.table.table-zebra .main td {
		border-top: 1px solid $border-color;
	}

	.table-rested.table.table-zebra tbody tr:nth-child(2n+1) td {
		background: white;
	}

	.tr-rest {
		text-align: center;
		color: $color-green-dark;

		td {
			background: $color-green-lighter !important;
			padding: 3px 0;
		}
	}
}