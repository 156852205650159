@import "../../styles/_variables.scss";

.Auth {
  height: 100vh;
  padding: 15px;
  color: $text-color;

  .check-legal, .login-register{
    font-size: 14px;
  }
}


.SubmitButton{
  margin-top: 30px;
	margin-bottom: 20px;
}

.remember-password{
  text-align: center;
  text-decoration: underline;
  margin-top: 15px;
  display: block;
  color: $primary;
}

.login-register{
  margin-top: 10px;
  text-align: center;
  
  a{
    // font-weight: bold;
   text-decoration: underline;
  }
}

.logo{
  margin: 20px auto 40px auto;
  display:block;
  width: 180px;
}

.feedback-error{
  text-align: center;
  color: $color-error;
}

.hello{
  font-size: 16px;
  text-align: center;
  max-width: 420px;
  margin: 10px auto 20px auto;
}

.page-invitation{

  max-width: 420px;
  margin: 0 auto;

  .user-data{
    font-size: 16px;
    background: white;
    line-height: 1.5em;
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px 5px;
  }

  .logo{
    margin-bottom: 20px;
  }

  .alert{
    text-align: center;
  }
}


@media all and (min-width: 480px) {
  .Auth {
    padding: 60px 0;

    form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
}