@import "./_variables.scss";

.card {
	border-radius: 0;
	border: none;
	padding: 20px;
	margin-bottom: 15px;
	position: relative;

	.btn-inline {
		display: inline-table;
		max-width: max-content;
	}

	.icon-locked {
		color: $grey-medium;
		position: absolute;
		right: 15px;
	}
}

.card-tag {
	color: white;
	width: 26px;
	height: 36px;
	top: -3px;
	right: 18px;
	position: absolute;
	filter: drop-shadow(2px 2px 2px rgba(black, 0.3));

	.card-tag-icon {
		font-size: 18px;
	}

	.card-tag-content {
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - 8px),
			50% 100%,
			0 calc(100% - 8px)
		);
		background: $primary;
		width: 30px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.card-title {
	font-size: 24px;
	font-weight: bold;

	&.card-title-colored {
		background: $grey-light;
		border: 3px solid $grey-light;
		padding: 4px 10px;
	}
}

.card-subtitle {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 7px;
}

.card-top {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap-reverse;
	align-items: flex-end;
	.avatar {
		order: 1;
		margin-bottom: 5px;
	}
	.card-top-info {
		width: calc(100% - 90px);
	}
}

.card-header {
	border-bottom: 1px solid $border-color;
	margin: -15px -15px 0 -15px;
	background: white;
	padding: 15px;

	.card-title {
		margin-bottom: 0;
	}
}

.card-content {
	flex-grow: 1;

	.card-label {
		color: $grey-label;
	}

	.form-group:last-of-type {
		margin-bottom: 0;
	}
}

.card-actions {
	display: flex;
	justify-content: flex-end;
	margin-bottom: -15px;
	margin-right: -15px;
	margin-top: -15px;

	&.bottom-right {
		position: absolute;
		right: 15px;
		bottom: 20px;
	}
}

@media #{$max-sm} {
	.card-title {
		font-size: 18px;
	}

	.card-top {
		.card-top-info {
			width: 100%;
		}
	}
}

@media #{$max-xs} {
	.page-home .card h2 {
		font-size: 20px;
	}
}
