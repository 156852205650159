@import "../../styles/_variables.scss";

.list-daytypes {
	.festivo {
		color: $label-color;
		display: inline-block;
		margin-left: 5px;
	}
}

.daytype-edit {
	.simple-row {
		justify-content: space-between;
	}

	.form-group-flex {
		// width: 110px;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}
}
