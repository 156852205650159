@import "../../styles/_variables.scss";

.page-transactions{
	.infinite-scroll-component{
		overflow: initial !important;
	}
}


.table-transactions{
	.td-valor{
		text-transform: lowercase;
	}

	&.table-mobile{
		.tr-head{
			td{
				border-bottom:0;
				padding-bottom:0;
			}
		}
		.tr-body{
			td{
				border-top:0;
				padding-top:5px;
			}
		}
		.label-top{
			display: block;
			margin-bottom: 5px;
			font-weight: 500;
		}
	}
}

