@import "../../styles/_variables.scss";

$cell-height: 52px; // 60px 81px
$cell-height-ubications: 60px; // 60px 81px
$cell-height-combo: 60px; // 100px 

.h-scroll {
	//width: 100%;
	//max-width: 100%;
	overflow-x: auto;
	transition: all 0.2s ease;
	scrollbar-width: thin;
	scrollbar-color: $scrollbar-color;
}

.form-group-periodo {
	max-width: 220px;

	.DateInput {
		width: 95px;
	}
}


.page-plans {
	.bloque-abrir {
		background: white;
		margin-top: 30px;
		padding: 30px 30px 20px 30px;

		.flex-group {
			max-width: 650px;
		}

		.SubmitButton {
			margin: 0;
		}

		@media #{$max-xs} {
			padding: 15px;

			.flex-group {
				display: block;
				max-width: 100%;
			}

			.form-group {
				margin-bottom: 10px;
			}

			.DateRangePicker {
				display: block;
			}

			.form-group-periodo,
			.DateRangePicker_picker {
				max-width: 100%;
			}

			.form-group-periodo {
				.DateInput {
					width: 40%;
				}
			}
		}
	}

	.list-recientes {
		margin-top: 40px;

		.plan-visit {
			height: 100%;
			justify-content: space-between;
		}

		.col-visit {
			margin-bottom: 30px;
		}

		h3 {
			margin-bottom: 15px;
		}

		.time-ago {
			color: $grey;
			font-style: italic;
		}

		.avatar-container {
			margin-bottom: 15px;
			height: 40px;
		}
	}
}

.page-plan {
	.btn-filter {

		.icon-maxmin,
		.icon-filter {
			font-size: 18px;
			color: $grey-label;
		}

		&.active {

			.icon-maxmin,
			.icon-filter {
				color: $primary;
			}
		}

		&:focus {
			box-shadow: none;
			border-color: none;
		}
	}

	.plan-pending,
	.plan-error {
		text-align: center;
		margin-top: 250px;

		.icono-plan {
			color: $grey-label;
			font-size: 36px;
			margin-bottom: 5px;
		}
	}

	.bloque-plan {
		background: white;
		padding: 15px;
		// margin-top: 30px;
		flex: 1;
	}

	.full-plan-container {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		// height: calc(100% - 40px);
		// height: calc(100vh - 280px);
		height: calc(100vh - 180px);

		.lds-spinner {
			margin-top: 200px;
		}

		.loading-overlay .lds-spinner {
			margin: 0;
		}

		&.maximized {
			height: calc(100vh - 130px); // es treu alçada dels filtres
		}
	}

	.shift-list-filter {
		align-self: self-start;

		.shift-list {
			// max-height: 350px;
			// max-height: 100%;
			max-height: calc(100vh - 375px);
		}
	}

	// filtro turnos/ubicacioes
	.combo-filter {
		.btn-default {
			padding-left: 5px;
			padding-right: 5px;
		}

		.nav-tabs {
			margin-right: 10px;

			.nav-item {
				.nav-link {
					margin-right: 10px;
					padding-top: 20px;
					padding-bottom: 8px;
				}

				&:last-child .nav-link {
					margin-right: 0;
				}
			}
		}
	}

	.resizable-fragment {
		height: 100%;
		display: flex;
	}

	.panel-plan {
		flex-grow: 1;
	}

	.plan-container {
		@extend .h-scroll;
		width: 100%;
		position: relative;
	}

	.heading {
		.btn-group-view {
			.btn {
				padding: 8px 10px;
			}

			.icon-zoom {
				font-size: 16px;
			}

			.icon-vertical {
				font-size: 20px;
				margin-left: -2px;
				margin-right: -2px;
			}

			.btn-flex {
				display: flex;
				align-items: center;

				.plus {
					font-weight: 500;
					font-size: 20px;
					line-height: 1px;
					margin-left: 2px;
					margin-right: 1px;
					color: $grey;
				}

				&.btn-primary {
					.plus {
						color: white;
					}
				}
			}
		}

		@media #{$max-sm} {
			margin-bottom: 0;
		}
	}

	.plan-periodo {
		// margin-bottom: 0;
		margin-left: 10px;
		font-size: 18px;
		font-weight: 500;
	}

	.head-hours-main {
		background-color: $primary-lightest;
	}

	.head-hours {
		white-space: nowrap;
		background-color: $grey-light;
		padding: 8px;
		min-width: 50px;
		text-align: center;
	}

	.shift-cell-hours-main {
		background-color: $primary-lightest;
	}

	.shift-list-items-hours {
		.shift-item {
			flex-grow: 0;
		}

		.shift-item.cell-q1 {
			height: 25% !important;
			font-size: 10px;
		}

		.shift-item.cell-q2 {
			height: 50% !important;
		}

		.shift-item.cell-q3 {
			height: 75% !important;
			// position: relative;

			// &:after {
			// 	content: '';
			// 	width: 50%;
			// 	height: 50%;
			// 	position: absolute;
			// 	right: 0;
			// 	bottom: 0;
			// 	background: white;
			// }
		}
	}

	// tabla base plan
	.table-plan {
		height: 1px;
		margin-bottom: 30px;

		.th-wrap {
			display: flex;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 100%;
			border-left: 1px solid $border-color;
			box-shadow: -1px 0px 0px 0px white;
			padding: 7px;
			background: white;
		}

		.th-day {
			text-align: center;
			line-height: 1.2em;
			text-transform: capitalize;
			min-width: 47px;
			height: 30px;

			span {
				display: block;
				line-height: 1.1em;
			}

			.span-day {
				font-size: 80%;
				font-weight: 400;
			}

			.span-num {
				font-weight: 700;
			}

			.th-wrap {
				padding-top: 3px;
				padding-bottom: 3px;
			}
		}

		thead th:first-child,
		tbody th {
			min-width: 200px;
			height: 100%;
			position: sticky;
			left: 0px;
			z-index: 2;
			padding: 0;

			.th-wrap {
				border-right: 1px solid $border-color;
			}
		}

		thead {
			th {
				font-size: 14px;
				padding: 0;
				height: auto;
				font-weight: 500;
				background: white;
				position: sticky;
				top: 0;
				height: 35px; // 52px
				border-bottom: 0;
				z-index: 1;

				.th-wrap {
					border-top: 1px solid $border-color;
					border-bottom: 1px solid $border-color;
					box-shadow: -1px -1px 0px 0px white;
				}

				&:first-child {
					z-index: 2;

					.th-wrap {
						padding: 8px 10px;
						text-align: left;
						min-width: 180px;
					}
				}
			}
		}

		tbody {
			td {
				&.disabled {
					background: $grey-disabled;
				}
			}
		}

		// tbody tr:last-child th {
		// 	.th-wrap {
		// 		// border-bottom: 0;
		// 	}
		// }
	}

	// tabla planificación
	.table-plan.table-plan-main {
		tbody {
			td {
				vertical-align: top;
				height: $cell-height;
				padding: 0;
			}
		}

		.plan-icon {
			color: white;
			position: absolute;
			font-size: 10px;

			&.icon-locked {
				bottom: 3px;
				left: 2px;
			}

			&.icon-clock {
				bottom: 3px;
				right: 2px;
				font-size: 12px;
			}

			&.icon-comments {
				bottom: 3px;
				left: 18px;
				font-size: 12px;
			}

			&.icon-edited {
				top: 2px;
				right: 2px;
				font-size: 12px;
			}

			&.icon-alert {
				color: red;
				top: 2px;
				left: 2px;
				font-size: 11px;
			}
		}

		.shift-separator {
			border-bottom: 2px solid black !important;
		}

		.btn-checkbox.active {
			border-color: $border-color;
		}

		.shift-list-items {
			min-height: 40px;
			height: 100%;

			.shift-item {
				height: 27px;
				min-width: 46px;
				font-size: 11.5px;
				border: 2px solid transparent;

				&:hover:not(.shift-item-intervals) {
					background: rgba(91, 73, 254, 0.4) !important;
				}


			}

			.shift-item-intervals {
				margin-bottom: 2px;
				flex-direction: column;
				flex-wrap: nowrap;
				flex-grow: auto !important;

				&:last-child {
					margin-bottom: 0;
				}

				.time-intervals {
					display: flex;
				}

				.time-interval {
					font-size: 12.5px;
					line-height: 1.1em;
					border: 1px solid transparent;
					padding: 0px 4px;
					border-radius: 8px;
					margin-right: 3px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.shift-cell {
			display: table-cell;
			position: static;

			.react-contextmenu-wrapper,
			.shift-item {
				display: flex;
				height: 100%;
				width: 100%;
				justify-content: center;
				align-items: center;
				position: relative;
			}

			&:hover {
				opacity: 1;
			}

			&.cut {
				opacity: 0.5;
			}

			&.dashed {
				opacity: 0.8;

				.shift-item {
					border: 2px $grey-label dashed;
					background: $grey-disabled !important;
				}
			}

			&.shift-cell-intervals {
				padding: 3px;

				.shift-item {
					margin-bottom: 0;
				}

				.shift-list-items {
					justify-content: center !important;
				}
			}

			&.shift-cell-ubications {
				overflow: hidden;

				.shift-item {
					position: relative;

					&:after {
						content: '';
						background: white;
						position: absolute;
						width: 100%;
						height: 100%;
						opacity: 0.8;
						z-index: 0;
					}

					.ubication-list {
						z-index: 1;
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;

						.ubication-item {
							height: 100%;
						}
					}

					.plan-icon {
						z-index: 1;
						// color: black;
					}

					.shift-container {
						color: black;
					}

					&.has-ubications {
						.shift-container {
							display: none;
						}
					}
				}
			}
		}



		.ubication-list-items {
			font-size: 12px;
			line-height: 1.1em;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;

			.ubication-item {
				padding: 1px;
				border: 2px solid transparent;
				display: flex;
				align-items: center;
				text-align: center;
				height: 100%;
				width: 100%;
				justify-content: center;
				cursor: pointer;
				line-height: 1em;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.shift-cell-combo {
			min-width: 70px;
			max-width: 70px;
			height: $cell-height-combo;

			.shift-item {
				.react-contextmenu-wrapper {
					.shift-container {
						min-width: 50%;
						text-align: center;
					}

					.ubication-list {
						width: 50%;
						height: 100%;
						display: flex;
						flex-direction: column;
						border-left: 1px solid #ccc;

						// &:before {
						// 	content: '';
						// 	background: rgba(255, 255, 255, 0.5);
						// 	position: absolute;
						// 	top: 0;
						// 	width: 100%;
						// 	height: 100%;
						// }

						.shift-separator {
							border-bottom: 0 !important;
						}

						.ubication-item {
							height: 100%;
							// z-index: 1;
							text-align: center;
							line-height: 1.1em;
							font-size: 12px;
						}
					}
				}

				&.has-ubications {
					.icon-locked {
						left: calc(50% - 12px);
					}
				}
			}
		}

		.avatar {
			display: block;
			margin-right: 5px;
			margin-bottom: 2px;
		}

		.employee-main {
			display: flex;
			justify-content: flex-start;
			// align-items: center;
			flex-direction: row;
		}

		.employee-link-name {
			color: $text-color;

			&:hover {
				color: $primary;
			}
		}

		.employee-info {
			font-weight: 400;
			color: $text-info;
			margin-top: -7px;
			margin-bottom: -3px;
			display: flex;
			font-size: 87%;
			max-width: 200px;
			margin-left: 30px;
			// display: none;
		}

		.substring {
			max-width: 150px;
		}

		.employee-function {
			margin-right: 4px;

			// color: $grey;
			&.substring {
				max-width: 80px;
			}
		}

		.employee-ubication {
			&.substring {
				max-width: 80px;
			}

			.icon {
				margin-right: -3px;
			}
		}


	}

	.table-plan.table-plan-main.table-ubications,
	.table-counter.table-ubications {
		tbody {
			td {
				height: $cell-height-ubications;
			}
		}
	}

	.table-plan.table-plan-vertical {
		thead th:first-child {
			min-width: 0;
		}

		thead .th-wrap-inverted {
			border-left: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			box-shadow: 1px 0px 0px 0px white;
			margin-left: -1px;
		}

		.th-day-content {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			background: white;
			border-left: 1px solid $border-color;

		}

		.th-employee {
			// 
		}

		thead tr th {
			border-bottom: 1px solid $border-color;
		}

		.th-day-extended {
			height: 100%;
			width: 70px;
			min-width: 70px;
			border-right: 1px solid $border-color;
		}

		.employee-main {
			align-items: center;
			padding: 5px 8px;

			.employee-info {
				margin: 0;
			}
		}
	}

	// counter ***********************
	.counter-container {
		padding-right: 10px;
		margin-left: 20px;
		position: relative;
		@extend .h-scroll;

		//max-width: 270px;

		&:before {
			content: "";
			height: 100%;
			width: 45px;
			position: absolute;
			top: 0;
			left: -45px;
			background-image: linear-gradient(90deg,
					rgba(255, 255, 255, 0) 0,
					rgba(255, 255, 255, 1) 100%);
			z-index: 1;
		}
	}

	// table counter assignments
	.table-counter {
		th {
			font-size: 13px;
			padding-top: 3px;
			padding-bottom: 3px;
			// height: 53.4px !important;
			line-height: 1.1em;
			height: 30px;
		}

		tbody {
			td {
				height: $cell-height;
			}
		}

		.counter-value {
			color: $text-color;
			border-bottom: 1px solid $primary;

			&:hover {
				color: $primary;
				text-decoration: none;
			}
		}

		// view combo
		&.table-grid-combo {
			td {
				height: $cell-height-combo;
			}
		}
	}

	// table hour conter
	.table-hourly-counter {
		margin-top: -30px;

		.main-col {
			min-width: 150px;
		}

		.td-hour {
			text-align: center;
			padding-left: 1px;
			padding-right: 1px;
			font-size: 14px;
		}

		// necesidades hourly
		&.table-needs {
			margin-top: -45px;
		}
	}

	.staffing-container {
		.plan-error {
			margin-top: 200px;
		}
	}

	.staffing-filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
		height: 40px;

		.staffing-filters-content {
			display: flex;
			align-items: center;
			padding-right: 10px;

			.form-group {
				margin-right: 10px;
				margin-bottom: 0;
			}
		}
	}

	// table staffing needs
	.table-needs {

		.th-day {
			cursor: pointer;

			&.selected .th-wrap {
				background: $primary-lightest;
				color: $primary;
			}

			&:hover {
				.th-wrap {
					background: $primary-lightest;
					color: $primary;
				}
			}
		}

		tbody {
			th {
				background: white;
			}

			.th-wrap {
				background: transparent;
			}

			td {
				min-width: 47px;

				&:not(.disabled) {
					cursor: pointer;
				}
			}
		}

		.need-ubication,
		.need-interval {
			// color: $text-info;
			font-weight: normal;
			font-size: 90%;
			margin-top: -2px;
			display: flex;
			align-items: center;

			.icon {
				margin-right: 5px;
			}
		}

		.need-ubication {
			margin-right: 10px;
		}

		.need-footer {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.current-cell {
			padding: 8px 10px;
			text-align: center;

			&.current-none {
				cursor: initial;
			}
		}

		.need-cell {
			display: table-cell;
			position: static;
			padding: 0;

			.react-contextmenu-wrapper {
				display: flex;
				height: 100%;
				width: 100%;
				justify-content: center;
				align-items: center;
				position: relative;
			}

			.need-icon {
				color: $grey;
				position: absolute;
				font-size: 12px;

				&.icon-edited {
					top: 2px;
					right: 2px;
				}
			}

			&:hover {
				opacity: 1;
			}

			&.active {
				color: white;

				.need-icon {
					color: white;
				}
			}

			&.shift-cell-intervals {
				padding: 3px;

				.shift-list-items {
					justify-content: center !important;
				}
			}
		}

		// view combo
		&.table-grid-combo {
			td {
				min-width: 70px;
				max-width: 70px;
			}
		}
	}
}


// plan shift edit

.plan-shift-edit {
	.plan-shift-head {
		margin-bottom: 20px;
		margin-top: -10px;
	}

	.btn-add {
		padding-left: 8px;
		padding-right: 8px;
		white-space: nowrap;
	}

	.hasError .form-time {
		color: $color-error !important
	}

	.table-zebra-reverse {
		.td-error {
			color: $color-error;
			background: #f2d0d4;
		}
	}

	.row-ubications {
		margin-bottom: 20px;
	}
}

// staffing levels edit
.staffing-overwrite-edit {

	.subtitle {
		color: $primary;
		margin-bottom: 0;
	}

	.staffing-overwrite-head {
		margin-top: -10px;

		strong {
			width: 70px;
			display: inline-block;
		}

		div {
			margin-bottom: 5px;
		}
	}

	.need-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 15px;

		.info-note {
			color: $grey-label;
			display: block;
			text-align: right;
			font-size: 85%;

			&:before {
				content: '*';
				margin-right: 5px;
			}
		}
	}

	.need-list {
		border: 1px solid $primary-lighter;

		li {
			border-bottom: 1px solid $primary-lighter;
			padding: 5px 10px;
			display: flex;
			align-items: center;

			&:last-child {
				border-bottom: none;
			}

			&:nth-child(even) {
				background-color: $primary-lightest;
			}
		}

		.form-group {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			label {
				width: 100px;
				margin-bottom: 0;
			}

			.form-control {
				width: 100px;
				margin-right: 15px;
			}
		}

		.needed-status {
			margin-right: 5px;
			;
		}

		.row-copy {
			display: flex;
			align-items: center;

			.switch {
				margin-left: 40px;
				margin-right: 7px;
				width: 35px;
				height: 20px;

				.react-switch-label {
					width: 35px;
					height: 20px;
				}

				.react-switch-button {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	.form-error {
		margin-top: 15px;
	}



}

// tooltip staffing levels assignments
.tooltip-staffing {
	.tooltip-inner {
		text-align: left;
	}

	/*
	.tooltip-employees{
		width: 100%;
		text-align: left;
	}*/
}



// autoplan
.switch-input-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;

	.form-group {
		margin-bottom: 0;
	}

	.form-group-input {
		max-width: 120px;
		margin-top: -15px;
	}
}

// popup plan-magic
.plan-magic-edit {
	.subtitle {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.tab-content .tab-pane {
		padding-top: 30px;
		min-height: 300px;
	}

	.alert-general-error {
		margin-top: -15px;
	}

	.form-group-flex.form-row-switch {
		margin-top: 0;
		margin-bottom: 20px;
		width: calc(100% - 150px);
	}

	.form-row-select {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		margin-top: -7px;

		label {
			font-weight: normal;
			width: calc(100% - 200px);
		}

		select {
			width: 200px;
		}
	}

	.switch-input-row {
		.form-control {
			text-align: right;
		}

		.form-row-switch {
			margin-bottom: 7px;
		}

		.input-group-text {
			width: 65px;
		}

		.form-group-segundos {
			max-width: 155px;

			.input-group-text {
				width: 95px;
			}
		}
	}

	.row-adding {
		.form-group-flex {
			justify-content: space-between;
		}

		select {
			min-width: 300px;
		}

		.form-label {
			font-weight: 400;
			padding-top: 10px;
		}

		.form-box {
			margin-left: 10px;
		}

		.btn {
			height: 40px;
			margin-left: 10px;
		}

		.list-tags {
			margin-top: 10px;

			li {
				padding: 3px 8px;

				.btn-tag-delete {
					margin-left: 3px;
				}
			}
		}

		.form-box-wrap {
			display: flex;
		}

		.row-adding-help {
			font-size: 85%;
			margin-top: 3px;

			.btn-link:first-of-type {
				margin-right: 7px;
			}
		}
	}
}

.plan-export {
	.form-group-periodo {
		// max-width: 380px;
		display: flex;
		flex-direction: column;

		.DateInput {
			width: 187px;
		}
	}

	.form-group-flex.form-row-switch {
		justify-content: start;
		padding-top: 10px;
		margin-bottom: 15px;

		label {
			font-weight: 500;
		}
	}
}


// plan magic


.chart-container {
	background: #fff;
	padding: 15px 0;
	margin-bottom: 30px;

	.title-chart {
		font-size: 20px;
		padding: 0 15px;
		font-weight: 900;
	}

	svg,
	.apexcharts-canvas {
		max-width: 100%;
	}

	.apexcharts-canvas {
		max-width: calc(100% - 5px);
	}
}


.page-magic-plan {
	.heading {
		margin-bottom: 5px;
	}

	.plan-periodo {
		margin-left: 0;
	}

	.no-results {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100px;
	}

	.btn-guardar {
		margin: 0;
	}

	.btn-guardar-footer {
		margin: 20px auto 70px auto;
		display: block;
	}

	.apexcharts-zoom-icon.apexcharts-selected svg {
		fill: $primary !important;
	}

	.apexcharts-pan-icon.apexcharts-selected svg {
		stroke: $primary !important;
	}


	.plan-ok {
		text-align: center;
	}

	.plan-ok-container {
		display: flex;
		align-items: center;
		max-width: 600px;
		margin: 30px auto 30px auto;
		background: white;

		h3 {
			font-size: 18px;
			margin-bottom: 15px;
		}

		.img-plan {
			width: 200px;
		}

		.plan-ok-content {
			padding: 30px;
		}

		.cobertura {
			max-width: 250px;
			margin: 0 auto 25px auto;

			p {
				margin-bottom: 5px;
			}
		}
	}


}