@import "../../styles/_variables.scss";

.resizable-panels{
	.resizer{
		background: $primary-lightest;
		width: 10px;
		position: relative;
		flex-shrink: 0;
		//margin-left:20px;

		&:after,
		&:before {
			content: "";
			border-left: 1px solid $primary; //888
			position: absolute;
			top: 50%;
			transform: translateY(-100%);
			right: 0;
			display: inline-block;
			height: 20px;
			margin: 0 3px;
		}
		&:before {
			left: 0;
		}
	}
}