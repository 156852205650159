@import "../../styles/_variables.scss";

.table {
	margin-bottom: 50px;
}

.email {
	margin-top: 5px;
	margin-bottom: 10px;
	display: block;
}

.form-new {
	.switch {
		margin-top: 5px;
	}
}

.heading,
.card-top {
	h3 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.calendar-left,
	.calendar-right {
		color: $primary;
		line-height: 20px;
		width: 15px;

		&:hover {
			color: $primary-light;
		}
	}

	.calendar-left {
		margin-right: 5px;
	}
}

// employees list
.page-employees {
	.grid-employees {
		[class*="col-"] {
			margin-bottom: 15px;
		}

		.card {
			height: 100%;
			margin-bottom: 0;

			.btn-primary {
				margin-top: 15px;
			}
		}
	}

	.table-employees {
		.employee-code {
			color: $grey-label;
		}

		.employee-email,
		.employee-phome {
			display: block;
		}

		&.table-desktop {
			.employee-code {
				display: block;
			}
		}

		&.table-mobile {
			.employee-name {
				display: block;
				font-weight: 500;
			}

			.employee-code {
				margin-right: 5px;
			}
		}
	}

	@media #{$max-xs} {
		.heading .heading-filters .btn-group {
			margin-left: 0;
		}
	}
}

// single employee
.page-employee {
	.row-buttons {
		margin-top: 30px;
	}

	.nav-tabs {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.nav-item-fichajes {
		margin-left: auto;
		margin-right: -30px;
	}

	.icon-external-link {
		margin-left: 2px;
		margin-bottom: 2px;
	}

	.avatar {
		margin-top: -10px;
		margin-bottom: 25px;
	}
}

.seniority {
	padding-top: 5px;

	.seniority-time {
		margin-right: 4px;
		//font-weight: 700;

		&:nth-child(2) {
			margin-left: 4px;
		}
	}
}

.page-employees {
	.seniority {
		padding-top: 0;
	}
}

.form-group-supervised {
	margin-left: 20px;
	margin-bottom: 0;

	.list-supervised {
		display: flex;

		li+li:before {
			content: ", ";
		}
	}
}


// employees order modal in group

.group-employees-order {}

.column-item.column-employees-order {
	padding: 8px 15px 8px 5px !important;
	align-items: center;
	justify-content: flex-start !important;

	.column-left {
		flex: 0 1;

		.column-move-icon {
			opacity: 0.2;
		}
	}

	&:hover {

		.column-move-icon {
			opacity: 0.6;
		}
	}
}


// employee magic plan
.employee-backup {
	.btn-icon {
		padding: 8px;
	}

	.employee-backup-name {
		margin-left: 10px;
		margin-right: 10px;
	}
}