@import "./_variables.scss";

.alink {
	margin-right: 4px;
	margin-left: 4px;
}

.button-link {
	background: none;
	padding: 0;
	border: 0;

	&.primary {
		color: $primary;

		&:hover {
			color: $primary-dark;
		}
	}
}

.button-icon {
	cursor: pointer;
}

.btn {
	font-size: 14px;
	padding: 8px 15px;
}

.btn-transparent {
	padding: 0;
	border: 0;
	background: transparent;
	box-shadow: none;

	&:focus {
		box-shadow: none;
		border-color: none;
	}
}

.btn-custom {
	border: none;
	width: 100%;
}

.btn-wand {
	white-space: nowrap;

	.icon-wand {
		margin-left: 7px;
	}
}

.btn-primary {
	border-color: $primary;
	background-color: $primary;
	color: white;

	&:hover {
		background-color: $primary-dark;
	}

	&:disabled {
		background-color: $primary-light;
		border-color: $primary-light;
	}
}

.btn-outline-primary {
	border-color: $primary;
	color: $primary;
	background: white;

	&:hover {
		background-color: $primary-light;
		border-color: $primary;
	}

	&:disabled {
		color: $primary-light;
		border-color: $primary-light;
		background: $primary-lightest;
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(91, 73, 254, 0.15);
		border-color: rgba(91, 73, 254, 0.4);
	}
}

.btn-link {
	border: 0;
	padding: 0;
	color: $primary;
	background: transparent;
	text-decoration: underline;

	&:hover {
		color: $primary-light;
		text-decoration: none;
	}

	&:disabled {
		color: $primary-light;
	}
}

.btn-default {
	border-color: $border-color;
	background-color: white;
	color: $text-color;

	&:hover {
		background-color: $grey-light;
	}

	&:disabled {
		background-color: $bg-disabled;
		border-color: $bg-disabled;
	}

	&:focus {
		box-shadow: 0 0 0 1px rgba(6, 6, 6, 0.08);
		border-color: $border-color;
	}

	.icon {
		color: $grey;
	}
}

.btn-link-danger {
	padding: 6px 0;
	border: 0;
	box-shadow: none;
	background: transparent;
	color: $color-red;
	display: block;
}

.btn-new {
	background-color: $primary;
	color: white;
	padding: 0;
	width: 40px;
	min-width: 40px;
	height: 40px;
	box-shadow: 0 4px 8px 0 rgba(91, 73, 254, 0.24);
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: $primary-dark;
	}

	svg {
		width: 24px;
		height: 24px;
		min-width: 24px;
		color: white;
	}

	&.btn-new-small {
		width: 30px;
		height: 30px;
		min-width: 10px;

		svg {
			width: 16px;
			height: 16px;
		}
	}
}

.btn-editable {
	text-align: left;
}

.btn-menu {
	padding: 15px 25px;

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		color: $primary;
	}
}

// dropdown
.dropdown-item.active,
.dropdown-item:active {
	background-color: $primary;
}

.btn-action {
	&:after {
		display: none;
	}

	&:focus {
		//box-shadow: 0 0 0 .2rem rgba(91, 73, 254,.15);
		//border-color: rgba(91, 73, 254,.4);
		box-shadow: none;
		border-color: transparent;

		svg {
			color: $primary;
		}
	}
}

.dropdown-item {
	padding-left: 15px;
	padding-right: 15px;

	.dropdown-icon {
		margin-right: 5px;
	}
}

.row-buttons {
	display: flex;
	align-items: center;

	.btn {
		margin-top: 0;
		margin-bottom: 10px;
		margin-right: 10px;
	}
}

@media #{$max-xs} {
	.row-buttons {
		.btn {
			width: 100%;
		}
	}

	.btn {
		padding: 10px 20px;
		font-size: 16px;
	}

	.dropdown-item {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}