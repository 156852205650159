@import "../../styles/_variables.scss";

.sidebar {
	padding: 10px 15px 15px 15px;
	background: white;
	width: 200px;
	box-shadow: 0 4px 8px 0 rgba(13, 10, 44, 0.04);
	color: $grey;
	height: 100vh;
	position: fixed;
	transition: all 0.1s ease;
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: $grey-medium $grey-light; // 1 barra 2 fons (firefox)
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.is_trial & {
		height: calc(100vh - 34px);
	}

	a,
	a:link,
	a:visited {
		color: $grey;
		padding: 12px 5px;
		display: block;

		&.active {
			color: $primary;
			background: $grey-light;
		}
	}

	.sidebar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.sidebar-logo {
			height: 30px;
			margin-left: 5px;
		}

		.sidebar-iso {
			height: 24px;
		}

		@media #{$min-sm} {
			.sidebar-iso {
				display: none;
			}
		}
	}

	li {
		padding: 0 5px;
		margin-bottom: 5px;

		svg {
			margin-right: 10px;
			width: 24px;
			height: 24px;
		}
	}

	.menu-secondary {
		margin-top: 15px;
		border-top: 1px solid $border-color;
		padding-top: 15px;
	}

	.menu-sub {
		padding-left: 0;

		li {
			padding: 0
		}

		a {
			padding: 5px 0 5px 15px;
			display: flex;

			&.active {
				background: transparent;
			}

			&:before {
				content: '';
				width: 10px;
				height: 15px;
				border-left: 1px dotted $grey;
				border-bottom: 1px dotted $grey;
				margin-right: 4px;
				margin-top: -5px;
			}
		}
	}

	.sidebar-bottom {
		// position: absolute;
		// bottom: 15px;
		// left: 20px;

		.link-help {
			display: inline-block;
			padding: 5px;
			margin-bottom: 10px;
			font-size: 20px;
			color: $grey-label;

			&:hover {
				color: $grey;
			}
		}

		.avatar {
			margin-bottom: 0;
		}

		.sidebar-menu-arrow {
			margin-left: 3px;
		}

		.sidebar-menu-profile {
			align-items: center;
			display: flex;
			cursor: pointer;

			img {
				margin-right: 8px;
				width: 36px;
				height: auto;
			}

			>span {
				font-weight: 500;
			}

			.sidebar-username {
				line-height: 1.2em;
			}

			@media #{$max-sm} {
				.sidebar-username {
					display: none;
				}
			}
		}
	}

	// desktop sidebar

	&.desktop-sidebar {
		.btn-close-sidebar {
			display: none;
		}

		.option-text {
			opacity: 1;
			transition: opacity 0.1s linear;
		}

		.menu-sub {
			.option-text {
				font-size: 12px;
			}
		}



		&.mini {
			width: 70px;

			.sidebar-header {
				display: block;

				.sidebar-logo-link {
					padding-top: 0;
					padding-bottom: 0;
				}

				.btn-menu {
					margin: 5px 0 10px -15px;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			.link-help {
				margin-left: 2px;
			}

			.sidebar-username {
				display: none;
			}

			li {
				padding: 0;
			}

			.menu-secondary-sub {
				display: none;
			}

			.option-text {
				opacity: 0;
				display: none;
			}

			.menu-sub {
				display: none;
			}

			.sidebar-logo {
				display: none;
			}

			.sidebar-iso {
				display: inline-block;
			}
		}

		@media #{$max-md} {
			width: 70px;

			.sidebar-header {
				display: block;

				.sidebar-logo-link {
					padding-top: 0;
					padding-bottom: 0;
				}

				.btn-menu {
					margin: 5px 0 10px -15px;
					padding-top: 10px;
					padding-bottom: 10px;
				}

				.sidebar-iso {
					display: block;
				}

				.sidebar-logo {
					display: none;
				}
			}

			.sidebar-username {
				display: none;
			}

			li {
				padding: 0;
			}

			.menu-secondary-sub {
				display: none;
			}

			.option-text {
				opacity: 0;
				display: none;
			}

			.menu-sub {
				display: none;
			}
		}



		@media #{$max-sm} {
			display: none;
		}
	}

	// mobile sidebar

	&.mobile-sidebar {
		background: $text-color;
		width: 300px;
		height: 100%;
		z-index: 10000;
		padding: 50px 0 30px 0;
		box-shadow: 0 4px 8px 2px rgba(13, 10, 44, 0.4);

		.sidebar-logo {
			display: none;
		}

		.sidebar-iso {
			display: block;
		}

		.btn-menu {
			display: none;
		}

		svg {
			color: white
		}

		li {
			padding: 0;
		}

		a,
		a:link,
		a:visited {
			color: white;
			padding: 10px 20px;
			display: block;

			&.active {
				color: white;
				background: $primary;
			}
		}

		.menu-secondary {
			// margin-top: 10px;
			// border-top-color: $primary;
			// padding-top: 10px;
			margin-top: 0;
			border-top: 0;
			padding-top: 0;
		}

		.menu-sub {
			a {
				//padding: 5px 0 5px 30px;
				padding-left: 30px;
				display: flex;

				&.active {
					&:before {
						border-left-color: white;
						border-bottom-color: white;
					}
				}
			}
		}

		.btn-close-sidebar {
			position: absolute;
			top: 10px;
			right: 15px;
			font-size: 24px;
		}

		.sidebar-bottom {
			display: none;
		}
	}
}

.popover-sidebar {
	padding: 10px 15px;
	background: $grey-light;


	.sidebar-menu-item {
		padding-top: 2px;
		padding-bottom: 2px;
		color: $primary;
		display: inline-block;

		&:hover {
			color: $primary-darker;
			text-decoration: underline;
		}
	}
}

.mobile-sidebar-overlay {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
	z-index: 9999;
}