@import "./_variables.scss";


.table {
	background: #fff;
	border: $border-color 1px solid;

	th {
		border-top: $border-color 1px solid;
	}

	thead {
		th {
			border-bottom: $border-color 1px solid;
			height: 70px;
			vertical-align: middle;
			font-weight: 600;
			font-size: 16px;
			line-height: 1.2em;

			&.table-caption {
				background: $primary-darker !important;
				color: white;
				text-align: center;
				font-size: 14px;
				line-height: 1em;
				height: auto;
			}
		}
	}

	td {
		vertical-align: middle;
	}

	&.table-zebra,
	&.table-zebra-reverse {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
		border: 0;

		thead {
			th {
				border: 0;
			}
		}

		th,
		td {
			border: 0;
		}
	}

	&.table-zebra {
		tbody {
			tr:nth-child(odd) {
				td {
					background: $grey-light;
				}
			}
		}
	}

	&.table-zebra-reverse {
		thead {
			th {
				background: $grey-light;
			}
		}

		tbody {
			tr:nth-child(even) {
				td {
					background: $grey-light;
				}
			}
		}
	}

	&.table-condensed {
		thead {
			th {
				height: auto;
				font-weight: 500;
			}
		}

	}

	.td-name {
		.avatar {
			margin-right: 10px;
		}

		.employee-name {
			color: $text-color;
		}
	}

	.td-name-content {
		display: flex;
		align-items: center;
	}

	.form-control {
		padding: 5px;
		height: 34px;
	}

	.label-mini {
		color: $label-color;
		font-size: 85%;
		display: block;
	}

	.no-result {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.btn-new {
		width: 25px;
		height: 25px;
		margin-top: -5px;
		margin-bottom: -5px;
		box-shadow: 0 2px 4px 0 rgba(91, 73, 254, 0.24);
		min-width: 25px;
		/*
		svg{
			color: $primary;
		}
		&:hover{
			background: $grey-light;
			svg{
				color: $grey;
			}
		}
		*/
	}

	.icon-locked {
		color: $grey-icon;
	}

	&.table-days {
		thead {
			th {
				font-size: 14px;
				text-align: center;
				padding: 6px 8px;
			}
		}

		td {
			padding: 0;
		}
	}
}

.td-icon {
	width: 40px;
	text-align: center;
}

.table-link {
	color: $text-color;
	border-bottom: 1px dotted $primary-light;

	&:hover {
		text-decoration: none;
		color: $primary;
		border-color: $primary;
	}
}

.td-actions {
	width: 40px;
	text-align: right;

	.btn-action {
		padding: 4px 0;
		line-height: 0;
	}

	.icon-locked {
		height: 24px;
	}
}

@media #{$max-xs} {
	.table {
		margin-bottom: 75px;
	}
}