@import "./_buttons.scss";

.row-narrow {
	margin-left: -5px;
	margin-right: -5px;

	[class*="col-"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.box {
	background: white;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
	padding: 20px;
}

// empty container
.empty-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 150px;

	p {
		text-align: center;
	}

	svg {
		font-size: 30px;
		margin-bottom: 10px;
		color: $grey-label;
	}
}



.react-autosuggest {
	position: relative;

	.btn-autosuggest-clear {
		position: absolute;
		top: 8px;
		right: 8px;
	}
}

.react-autosuggest__container {
	position: relative;

	.react-autosuggest__suggestions-container {
		position: absolute;
		width: 100%;
		max-height: 315px;
		background: white;
		border: 1px solid $border-color;
		z-index: 5;
		border-radius: 4px;
		display: none;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: $scrollbar-color;

		&.react-autosuggest__suggestions-container--open {
			display: block;
		}

		.react-autosuggest__suggestions-list {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		.react-autosuggest__suggestion {
			padding: 6px 8px;
			cursor: pointer;

			&.react-autosuggest__suggestion--highlighted {
				background: $bg-color;
			}

			.suggestion-main {
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
			}

			.suggestion-info {
				display: block;
				color: $label-color;
				font-size: 13px;
			}
		}
	}
}


// dropzone

.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	border-width: 2px;
	border-radius: 2px;
	border-color: #eeeeee;
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border .24s ease-in-out;

	p {
		margin-bottom: 0;
	}
}

.dropzone-files {
	margin-top: 10px;

	.file-info {
		max-width: calc(100% - 40px);
		display: flex;
		align-items: center;
		width: 100%;

		.file-name {
			display: block;
			width: calc(100% - 55px);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.file-size {
			color: $grey-label;
			margin-left: 5px;
			font-size: 80%;
		}

		.modal-body.disabled & {
			max-width: 100%;
		}
	}



	.file-item {
		.file-info {
			cursor: pointer;

			.file-name {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
					color: $primary;
				}
			}
		}
	}


	.btn-icon {
		padding: 2px 5px;
		align-self: flex-end;
	}

	.file-saved {
		color: $color-green-dark;
	}

	li {
		border-bottom: 1px solid $grey-disabled;
		padding: 1px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:last-child {
			border-bottom: 0;
		}
	}
}

.twitter-picker {
	z-index: 10;
}


// tooltips

.tooltip-alerta {
	max-width: 270px;
	align-items: center;


	li {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}

		.tooltip-icon {
			margin-right: 10px;
			font-size: 16px;
			max-width: 16px;
			min-width: 16px;
		}
	}
}


// filter dropdown

.dropdown-filter {
	.dropdown-menu {
		min-width: 180px;

		.dropdown-header {
			padding: 10px 15px 0 15px;
			font-size: 1.1em;
		}

		.dropdown-content {
			padding: 15px;
			font-size: 0.9em;

			.form-group {
				margin-bottom: 10px;
			}

			.form-switch {
				.form-label {
					white-space: nowrap;
				}
			}
		}

		.form-label {
			font-weight: normal;
		}

		.dropdown-footer {
			padding: 0 15px 10px 15px;
		}
	}
}

// sortable columns

.column-item {
	z-index: 999 !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: white;

	label {
		margin-bottom: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		user-select: auto !important;
		display: inline-flex;
		flex-direction: column;
		flex-flow: wrap;
	}

	.column-move {
		cursor: grab;
		padding: 3px 10px;
	}

	.column-move-icon,
	.column-label-btn {
		transition: all .25s ease-in-out;
		-moz-transition: all .25s ease-in-out;
		-webkit-transition: all .25s ease-in-out;
		opacity: 0;

		&:hover {
			color: $primary;
		}
	}

	.column-left {
		display: flex;
		align-items: flex-start;
		flex: 1;
		padding-right: 15px;
	}

	.column-item-label {
		font-weight: 500;
	}

	.column-label-btn {
		@extend .btn-transparent;
		padding: 8px;
	}

	.column-label-edit {
		background: transparent;
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid $primary-lightest;
		padding: 0;
		height: 18px;
		//font-size: 90%;
		display: block;
		//margin-top: -1px;
		margin-bottom: 1px;
		width: 100%;

		&:focus {
			box-shadow: none !important;
		}
	}

	&:nth-child(odd) {
		background: $grey-light;
	}

	&:hover {

		.column-move-icon,
		.column-label-btn {
			opacity: 0.6;
		}
	}

	&.column-item-active {
		background: white;
		box-shadow: 0 4px 8px 0 rgba(91, 73, 254, 0.24);
		cursor: grabbing !important;

		&:hover {
			cursor: grabbing !important;
		}

		.column-move-icon {
			opacity: 0.4;
		}
	}

}

// loading overlay

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.relative {
	position: relative;
}