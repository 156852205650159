@import "./_variables.scss";
@import "./_buttons.scss";
@import "./_utils.scss";
@import "./_tables.scss";
@import "./_forms.scss";
@import "./_cards.scss";
@import "react-redux-toastr/src/styles/index";



body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $theme-font;
	background-color: $bg-color;
	color: $text-color;
	font-size: 14px;
	max-width: 100%;

	@media #{$max-xs} {
		font-size: 16px;
	}
}

* {
	box-sizing: border-box;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

a,
.link {
	color: $primary;
	cursor: pointer;
}

.inline-block {
	display: inline-block !important;
}

.regular {
	font-weight: 400 !important;
}

.pull-right {
	float: right !important;
}

.hidden {
	display: none !important;
}

.container-sm {
	max-width: 700px;
	margin: 0 auto;
}

.nowrap {
	white-space: nowrap;
}

.capitalize {
	text-transform: capitalize;
}

.aright {
	text-align: right;
}

.aleft {
	text-align: left;
}

.acenter {
	text-align: center;
}

.substring {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.color-green {
	color: $color-green;
}

.color-green-dark {
	color: $color-green-dark;
}

.color-red {
	color: $color-red;
}

.bg-green {
	background: $color-green-light;
}

.bg-red {
	background: $color-red-light;
}

.simple-row {
	display: flex;

	>* {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.flex-center {
	display: flex;
	align-items: center;
}

.flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.emoji {
	font-size: 24px;
}

.lowercase {
	text-transform: lowercase;
}

.notfound {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;

	h1 {
		font-weight: bold;
	}

	h3 {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 24px;
	}

	&.page-notfound {
		padding-top: 130px;

		h2 {
			font-size: 24px;
		}
	}
}

.no-results {
	margin-top: 40px;
}

.app_container {
	display: flex;
	height: 100vh;
	justify-content: space-between;

	.app.is_trial & {
		padding-top: 34px;
	}

}

.main-content {
	padding: 15px 30px 30px 230px;
	width: 100%;

	@media #{$min-sm} {
		&.mini {
			padding-left: 100px;
		}
	}
}

.full-height-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.heading {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 15px;

	h3 {
		font-size: 20px;
	}

	.btn {
		margin-bottom: 15px;
	}

	.btn,
	.form-group {
		margin-bottom: 0;
	}

	@media #{$max-sm} {
		flex-wrap: wrap;

		.title {
			line-height: 1.2em;
		}
	}

	.heading-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.btn {
			margin-left: 15px;

			&.dropdown-toggle {
				margin-left: 0;
				padding-right: 0;
			}
		}

		.btn-group {
			.btn {
				margin-left: 0;
			}
		}
	}

	.heading-filters {
		display: flex;
		justify-content: flex-end;

		>* {
			margin-left: 15px;

			&:first-of-type {
				margin-left: 0;
			}
		}

		@media #{$max-sm} {
			flex-direction: row;
			margin-top: 10px;
			order: 2;
			flex-basis: 100%;
			justify-content: flex-start;
		}

		@media #{$max-xs} {
			display: block;
			width: 100%;
			margin-bottom: 5px;

			.form-group {
				margin-bottom: 5px;
				max-width: 100%;
				margin-left: 0;
			}

			.DateRangePicker {
				width: 100%;
				margin-left: 0;

				.DateRangePickerInput {
					display: block;
				}
			}
		}

		.form-switch {
			display: flex;
			align-items: center;

			.form-label {
				font-weight: 400;
				margin-left: 5px;
				cursor: pointer;
				line-height: 1.15em;
			}

			.switch {
				width: 35px;
			}

			.react-switch-label {
				width: 35px;
				height: 20px;

				.react-switch-button {
					height: 16px;
				}
			}
		}
	}

	.heading-left {
		margin-right: auto;

		h1 {
			margin-bottom: 0;
		}
	}

	.heading-right {
		text-align: right;

		h3 {
			margin-bottom: 0;
		}
	}

	>.title {
		margin-right: auto;
	}
}

.heading,
.subheading {
	.summary {
		font-size: 13px;
		margin-top: 5px;
		color: $grey;
		display: block;

		.summary-icon {
			font-size: 16px;
			margin-right: 2px;
		}

		strong {
			font-weight: 500;
			white-space: nowrap;
		}

		@media #{$max-xs} {
			margin-bottom: 10px;
		}
	}
}

.title {
	font-size: 28px;
	font-weight: bold;
	line-height: 1.25em;
	margin-bottom: 0;

	&.acenter {
		text-align: center;
	}
}

.title2 {
	font-size: 24px;
	font-weight: bold;
	line-height: 1.38em;
	margin-bottom: 0;
	color: $primary;

	&.acenter {
		text-align: center;
	}
}

.subtitle {
	font-size: 18px;
	font-weight: bold;
}

.text-info {
	color: $text-info !important;
}

.page-content {
	padding: 30px;
	background: white;
	border-top: 2px solid $primary-lighter;
	border-bottom: 2px solid $primary-lighter;
}

.content-heading {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.bolita {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 auto;

	&.bolita-true {
		background-color: $color-green;
	}

	&.bolita-false {
		background-color: $color-red;
	}
}

.tick-default {
	font-size: 20px;
	color: $color-green-dark;
}

.no-more-results {
	color: $grey;
	text-align: center;
	margin-top: -20px;
	margin-bottom: 50px;
}

// tabs

.nav-tabs {
	.nav-link {
		border: none;
		border-bottom: 3px solid transparent;
		color: $text-color;
		font-weight: 500;
		padding-left: 0;
		padding-right: 0;
		margin-right: 20px;
		font-size: 14px;

		&.active {
			border-color: $primary;
			color: $primary;
			background-color: transparent;
		}
	}

	@media #{$max-xs} {
		.nav-link {
			padding-top: 5px;
			padding-bottom: 5px;
			margin-right: 15px;
		}
	}
}

.tab-content {
	.tab-pane {
		padding: 20px 0;
		min-height: 400px;

		.title {
			color: $primary;
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
}

// states

.estado {
	text-transform: uppercase;

	&.estado-si {
		color: $color-green;
	}

	&.estado-no {
		color: $color-red;
	}
}

// toastr
.redux-toastr {
	.toastr {
		min-height: 50px;

		&.rrt-success {
			background: $color-green-dark;
		}

		&.rrt-info {
			.rrt-title {
				margin-bottom: 0;
			}
		}

		&.rrt-success,
		&.rrt-warning,
		&.rrt-info {
			.rrt-middle-container {
				margin-left: 45px;
				padding-top: 12px;
				width: 75%;
			}

			.rrt-left-container {
				width: 40px;

				.rrt-holder {
					width: 40px;
					height: 40px;

					.toastr-icon {
						width: 20px !important;
						height: 20px !important;
					}
				}
			}

			.rrt-title {
				display: none;
			}

			.close-toastr span {
				right: 5px;
				color: white;
			}
		}
	}
}

.Toastify {
	font-family: $theme-font !important;
	font-size: 14px;

	.Toastify__toast-body {
		align-items: flex-start;
	}

	.Toastify__toast-icon {
		margin-top: 5px;
	}

	.lista-empleados {
		padding-left: 15px;

		li {
			list-style: disc;

		}
	}
}

// tag list

.list-tags {
	li {
		padding: 4px 15px;
		display: inline-flex;
		align-items: center;
		border-radius: 12px;
		margin-right: 7px;
		margin-bottom: 7px;

		&.tag-default {
			background: $grey-light;
		}

		&.tag-delete {
			padding-right: 10px;
		}

		&:last-child {
			margin-right: 0;
		}

		&.tag-comma {
			border-radius: 0;
			padding: 0;
			margin-right: 5px;
			background-color: transparent;

			&:after {
				content: ", ";
			}

			&:last-child {
				&:after {
					content: "";
				}
			}
		}
	}

	.btn-tag-delete {
		margin-left: 5px;
		line-height: 1em;
	}

	.table-zebra & {
		li {
			padding: 2px 10px;
			margin-bottom: 2px;
			margin-right: 4px;
			font-size: 13px;

			&.tag-default {
				background-color: white;
				border: 1px solid $border-color;
			}
		}
	}
}

.info-text {
	color: $grey;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 0;
	display: flex;
	align-items: center;

	.info-icon {
		margin-right: 10px;
		font-size: 18px;
	}
}

.alert {
	.alert-intro {
		margin-bottom: 0;
	}
}

.info-note {
	padding: 15px;
	background-color: $grey-light;

	.info-intro {
		margin-bottom: 7px;
		display: flex;
		align-items: center;
	}

	ul {
		li {
			list-style-type: disc;
			margin-left: 35px;
		}

	}

	.info-icon {
		margin-right: 7px;
		font-size: 18px;
		color: $grey-label;
	}
}

.dropdown-menu {
	border-color: $border-color;
	border-radius: 4px;

	.dropdown-item {
		&:hover {
			background: $grey-light;
		}
	}
}

.w100 {
	width: 100%;
}

// primeros pasos

.first-steps {
	text-align: center;
	margin: 220px auto 0 auto;
	max-width: 800px;

	.title2 {
		margin-bottom: 5px;
	}

	.icono-pasos {
		color: $grey-label;
		font-size: 36px;
		margin-bottom: 5px;
	}

	p {
		line-height: 1.7em;
		font-size: 16px;
	}
}

// ended trial

.app.is_trial_ended {

	.desktop-sidebar .menu-core,
	.desktop-sidebar .menu-secondary,
	.header-menu ul li:not(.option-logout),
	.sidebar-bottom-menu ul li:not(.option-logout),
	.icon-help {
		display: none;
	}

	.btn-menu {
		visibility: hidden;
		padding: 5px;
	}

	.header-menu-profile {
		cursor: initial;
	}

	.header-logo-link,
	.sidebar-logo-link {
		pointer-events: none;
	}

}

.page-end-trial {
	text-align: center;
	max-width: 600px;
	margin: 130px auto 0 auto;

	.main-logo {
		margin-bottom: 25px;
		width: 200px;
	}

	h1 {
		font-weight: bold;
		color: $primary;
		margin-bottom: 20px;
		font-size: 32px;
	}

	p {
		font-size: 16px;
	}
}

// calendar bullets

.calendar-bullets {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -2px;

	.bullet-item {
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-left: 3px;

		&:first-child {
			margin-left: 0;
		}
	}
}

// font size

.f12 {
	font-size: 12px;
}

// margins

.m0 {
	margin: 0 !important;
}

.mt0 {
	margin-top: 0 !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb5 {
	margin-bottom: 5px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb25 {
	margin-bottom: 25px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.mb50 {
	margin-bottom: 50px !important;
}

.mb70 {
	margin-bottom: 70px !important;
}


.ml5 {
	margin-left: 5px !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mr10 {
	margin-right: 10px !important;
}


// intercom

.intercom-app iframe {
	z-index: 1 !important;
}

// media queries
@media #{$max-lg} {
	.mobile-hidden {
		display: none;
	}
}

@media #{$max-md} {
	.main-content {
		padding: 30px 30px 30px 100px;
	}
}

@media #{$max-sm} {
	.main-content {
		// width: 100%;
		padding: 65px 20px 20px 20px;
	}

	.title {
		font-size: 28px;
	}

	.page-container {
		max-width: 100%;
		padding: 0;
	}

	.intercom-lightweight-app-launcher.intercom-launcher {
		display: none !important;
	}

	.hidden-sm {
		display: none;
	}
}

@media #{$max-xs} {
	.title {
		font-size: 24px;
	}

	.hidden-xs {
		display: none;
	}
}