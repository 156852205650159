// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap);

// Font Variables
$theme-font: "Open Sans", sans-serif !important;

$primary: black !important;



@import "../../_main.scss";

body {
	--primary: #000;
	--primary-light: #666;
}

/* General */

.title {
	font-size: 24px;
}

.modal-title {
	font-size: 24px;
	font-weight: bold;
}

/* Layout */

.sidebar {

	.sidebar-logo,
	.sidebar-iso {
		width: auto;
		height: 50px;
		margin-left: -10px;
	}

	.sidebar-logo-link {
		padding: 0;
	}
}

.header {

	.header-logo,
	.header-iso {
		width: 50px;
		height: auto;
	}
}


/* Dashboard */

.page-dashboard {
	.card {
		h2 {
			font-size: 21px;
			font-weight: bold;
		}
	}
}